<template>
    <div class="detail" v-if="info">
        <div class="title">{{ info.title }}</div>
        <div class="time">
            {{ "活动竞赛时间" | language }}:
            <span class="color-date">{{ info.beginAt }}</span>
            <span class="color-date">{{ "到" | language }}</span>
            <span class="color-date">{{ info.endAt }}</span>
        </div>
        <div class="time">
            {{ "报名截止时间" | language }}:<span class="color-date">
                {{ info.deadline }}</span
            >
        </div>
        <div class="color-title">{{ "内容介绍" | language }}</div>
        <div class="content">{{ info.desc }}</div>
        <div class="color-title">{{ "报名方式" | language }}</div>
        <Button
            type="primary"
            :disabled="
                !info.canOnlineSignup || new Date(info.deadline) < new Date()
            "
            size="large"
            style="margin-bottom:20px;"
            @click="showDialog"
            >{{ "在线报名" | language }}</Button
        >
        <div class="content">{{ info.signupMethod }}</div>
        <div class="download" v-if="info.docs.length">
            <div class="download-title">{{ "相关文档" | language }}：</div>
            <div class="download-list">
                <div
                    class="download-item click-op"
                    v-for="(item, index) in info.docs"
                    :key="index"
                    @click="handleClick(item)"
                >
                    {{ getFileName(item) }}
                </div>
            </div>
        </div>
        <dialog-submit-competition ref="add"></dialog-submit-competition>
    </div>
</template>

<script>
import { downloadMinxin } from "../../mixin/download.js";
import DialogSubmitCompetition from "../../compontents/dialog/information/DialogSubmitCompetition.vue";
export default {
    mixins: [downloadMinxin],
    components: {
        DialogSubmitCompetition
    },
    data() {
        return {
            id: "",
            info: null
        };
    },
    mounted() {
        this.id = this.$route.params.id;
        this.fetchData();
    },
    methods: {
        showDialog() {
            this.$refs.add.edit(this.id);
        },
        //下载
        handleClick(row) {
            // let type = this.getFileType(row.url);
            // this.download({ src: row.url, name: row.name, type });
            window.open(row.url);
        },
        //根据url获取文件后缀类型
        getFileType(url) {
            return url.split(".").slice(-1)[0];
        },
        getFileName(item) {
            let type = this.getFileType(item.url);
            if (this.getFileType(item.name) === type) return item.name;
            return `${item.name}.${type}`;
        },
        async fetchData() {
            let res = await this.$http.get(
                `/information/competition/${this.id}`
            );

            this.info = res;
        }
    }
};
</script>

<style lang="less" scoped>
.detail {
    text-align: left;
    min-height: 800px;
    .title {
        font-size: 30px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.9);
        line-height: 32px;
    }
    .time {
        margin-top: 20px;
        height: 24px;
        font-size: 16px;
        font-weight: 500;
        color: #f55e5a;
        line-height: 24px;
        .color-date {
            margin-left: 5px;
            color: #000015;
        }
    }
    .color-title {
        margin-top: 40px;
        margin-bottom: 20px;
        text-align: left;
        height: 32px;
        font-size: 22px;
        color: rgba(0, 0, 0, 0.9);
        line-height: 32px;
    }
    .content {
        font-size: 16px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.9);
        line-height: 22px;
    }
    .download {
        margin-top: 20px;
        display: flex;
        align-items: flex-start;

        font-size: 16px;

        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
        .download-title {
        }
        .download-list {
            .download-item {
                color: rgba(0, 145, 255, 1);
                margin-bottom: 20px;
            }
        }
    }
}
</style>
