<template>
    <!--  活动竞赛--在线报名弹框 -->
    <Modal v-model="show" :title="'活动竞赛在线报名' | language">
        <Form
            :model="model"
            ref="form"
            :rules="ruleValidate"
            label-position="top"
        >
            <FormItem :label="'姓名：' | language" prop="name">
                <Input
                    v-model="model.name"
                    :placeholder="'请填写姓名' | language"
                ></Input>
            </FormItem>
            <FormItem :label="'学号：' | language" prop="code">
                <Input
                    v-model="model.code"
                    type="number"
                    :placeholder="'请填写学号' | language"
                ></Input>
            </FormItem>
            <FormItem :label="'专业：' | language" prop="major">
                <Input
                    v-model="model.major"
                    :placeholder="'请填写专业' | language"
                ></Input>
            </FormItem>
            <FormItem :label="'申请材料：' | language">
                <Upload
                    ref="upload"
                    multiple
                    :show-upload-list="true"
                    :data="qnUploadData"
                    :before-upload="beforeFileUpload"
                    :on-success="handleSelectFile"
                    :on-remove="removeFileList"
                    action="https://upload.qbox.me"
                >
                    <Button
                        type="primary"
                        icon="ios-cloud-upload-outline"
                        size="small"
                    >
                        {{ "上传文件" | language }}</Button
                    >
                </Upload>
            </FormItem>
        </Form>
        <div slot="footer" class="container-end">
            <Button size="large" @click="show = false">{{
                "取消" | language
            }}</Button>
            <Button
                type="primary"
                size="large"
                :loading="loading"
                @click="save"
                >{{ "确定" | language }}</Button
            >
        </div>
    </Modal>
</template>

<script>
import uuidv1 from "uuid/v1";
import { qiniuUploadMixin } from "../../../mixin/qiniuUpload";
import { languageTranslation } from "../../../assets/util/language";
export default {
    mixins: [qiniuUploadMixin],
    data() {
        return {
            show: false,
            loading: false,
            model: {
                name: "",
                code: "",
                major: "",
                docs: []
            },
            id: "",

            ruleValidate: {
                name: [
                    {
                        required: true,

                        message: languageTranslation("姓名不能为空"),
                        trigger: "blur"
                    }
                ],
                code: [
                    {
                        required: true,

                        message: languageTranslation("学号不能为空"),
                        trigger: "blur"
                    }
                ],
                major: [
                    {
                        required: true,

                        message: languageTranslation("专业不能为空"),
                        trigger: "blur"
                    }
                ]
            }
        };
    },

    methods: {
        //上传部分
        beforeFileUpload(file) {
            let ext = file.name.split(".").reverse()[0];
            if (!ext) {
                this.$Message.error({
                    background: true,
                    content: languageTranslation("不支持无后缀名文件上传")
                });
            }

            this.qnUploadData.key = `${uuidv1()}.${ext}`;
            let isLt10M = file.size / 1024 / 1024 < 10;

            if (!isLt10M) {
                this.$Message.error({
                    background: true,
                    content: `${languageTranslation(
                        "上传文件大小不能超过"
                    )}10MB`
                });
                return false;
            }

            return isLt10M;
        },

        handleSelectFile(res, file) {
            let src = this.joinCDN(res.key);

            this.uploadFile(src, file.name, file.size);
        },
        async uploadFile(src, name, size) {
            this.model.docs.push({ url: src, name });
            // this.$Spin.show();
            //   await this.httpFileUpload(src, name, size);
            // this.$Spin.hide();
        },
        async httpFileUpload(src, name, size) {
            let type = name.split(".").reverse()[0];
            name = name.split(".").reverse()[1];
            let data = {
                folder: this.selectedFolder,
                src,
                type,
                name,
                permiss: 3,
                size
            };
            this.$Message.success(`${languageTranslation("上传成功")}`);
            let file = await HttpUtil.post("/filestore", data);
            this.info.fileList.push(file);
            this.info.fileList = [...new Set([...this.info.fileList])];
        },
        removeFileList(file) {
            let index = this.model.docs.findIndex(
                item => item.url.indexOf(file.response.key) >= 0
            );
            if (index >= 0) {
                this.model.docs.splice(index, 1);
            }
        },
        edit(id) {
            this.id = id;
            this.show = true;
        },
        messageWarningFn() {
            setTimeout(() => {
                this.loading = false;
                this.$nextTick(() => {
                    this.loading = true;
                });
            }, 500);
        },

        async save() {
            this.$refs.form.validate(async valid => {
                if (valid) {
                    this.loading = true;
                    await this.$http.post(
                        `/information/competition/${this.id}`,
                        this.model
                    );
                    this.$Message.success(languageTranslation("提交成功!"));
                    this.loading = false;
                    this.show = false;
                    this.$data = this.$options.data();
                } else {
                    return this.messageWarningFn();
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped></style>
